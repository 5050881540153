<template>
  <v-container fluid class="text-center">
    <v-row class="mt-10" justify="center">
      <v-col cols="12" lg="6">
        <v-card class="mx-auto" elevation="5" max-width="500">
          <v-card-title class="secondary justify-center mb-3 main-title">
            Perfil
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row cols="12" justify="center">
                <v-icon size="75" color="primary">mdi-account-circle</v-icon>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.detail.email" label="Email" readonly outlined dense></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.detail.nombre" label="Nombre" readonly outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="forms.detail.apellido_paterno" label="Apellido paterno" readonly outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="forms.detail.apellido_materno" label="Apellido materno" readonly outlined dense></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.detail.roles" label="Roles" readonly outlined dense></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.detail.empresas" label="Empresas" readonly outlined dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay absolute :value="loading" opacity="0.8">
      <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
    </v-overlay>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'

export default {
  name: 'profile',
  data: () => ({
    loading: false, 
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    forms: {
      detail: {
        email: '',
        nombre: '',
        apellido_materno: '',
        apellido_paterno: '',
        roles: '',
        empresas: ''
      }
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.loading = true
      this.axios.get(services.routes.user + '/profile')
        .then(response => {
          let data = response.data.data
          this.forms.detail.email = data.email
          this.forms.detail.nombre = data.nombre
          this.forms.detail.apellido_materno = data.apellido_materno
          this.forms.detail.apellido_paterno = data.apellido_paterno
          this.forms.detail.roles = data.roles
          this.forms.detail.empresas = data.empresas
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
  .main-title {
    font-size: 28px;
    color: #fff;
  }
</style>
